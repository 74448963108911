export const MenuItems = [
    {
      id: 1,
      title: "Gun Raffle",
      path: "events_GunRaffle",
      cName: "aboutus-dropdown-link",
    },
    {
      id: 2,
      title: "ID (Tootsie Roll) Drive",
      path: "events_IDDrive",
      cName: "aboutus-dropdown-link",
    },
    {
      id: 3,
      title: "Blood Drives",
      path: "events_bloodDrive",
      cName: "aboutus-dropdown-link",
    },
    {
      id: 4,
      title: "Forty Days for Life",
      path: "events_fortyDays",
      cName: "aboutus-dropdown-link",
    },
    {
        id: 5,
        title: "Family Fun Night",
        path: "events_familyFunNight",
        cName: "aboutus-dropdown-link",
      },
      {
        id: 6,
        title: "Annaul Golf Tournament",
        path: "events_golf",
        cName: "aboutus-dropdown-link",
      },
      {
        id: 7,
        title: "Coats for Kids",
        path: "events_coats4kids",
        cName: "aboutus-dropdown-link",
      },
      {
        id: 4,
        title: "KofC Bowling League",
        path: "events_bowling",
        cName: "aboutus-dropdown-link",
      },
      {
        id: 8,
        title: "MCHS Scholarship",
        path: "events_scholarship",
        cName: "aboutus-dropdown-link"
      }
  ];