
import {BrowserRouter as Router} from 'react-router-dom'
import Home from './pages'
//import { NavBarContainer } from './components/Navbar/NavbarElements';

function App() {
  return (
   <Router>
      <Home />
   </Router>
     
  );
}

export default App;
