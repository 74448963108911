import styled from 'styled-components'
import {Link} from 'react-scroll'

export const FooterContainer = styled.footer`
    background-color: #232c63;
`

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
    color: #F6B519;
`
export const FooterLinkText = styled.h2`
    font-size: 12px;
    color: white;
`

export const FooterLinkText2 = styled.h2`
    font-size: 12px;
    color: #EF3D3B;
`

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover{
        color:#01bf71;
        transition: 0.3s ease-out
    }
`
export const FooterLogoContainer = styled.div`
    height: 30px;
    width: 30px;
    background-color:red;
`

export const footerImage = styled.img`


`