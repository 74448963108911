import React, {useState} from 'react'
import { Link as LinkR} from 'react-router-dom'
import Video  from '../../videos/droneView.mov'
import Button from '../../components/ButtonElement'
import { HeroContainer, 
         HeroBG, 
         VideoBG, 
         HeroContent, 
         HeroH1, 
         HeroP, 
         HeroBtnWrapper,
         ArrowForward, 
         ArrowRight} 
         from './HeroElements'

const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }


  return (
    <HeroContainer id="home">
        <HeroBG>
            <VideoBG autoPlay loop muted src={Video} type='video/mp4'/>
        </HeroBG>
        <HeroContent>
            <HeroH1>Alton Knights of Columbus <br/> Council 460</HeroH1>
            <HeroP>
                Serving the parishes of Saints Peter & Paul and Saint Mary’s 
            </HeroP>
            <HeroBtnWrapper>
               <a href='https://www.kofc.org/secure/en/join/join-kofc.html' >
                <Button to='joinTheKnights' onMouseEnter={onHover} onMouseLeave={onHover}>
                Join the Knights {hover ? <ArrowForward /> : <ArrowRight/>}
               </Button>
               </a>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection