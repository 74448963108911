import React, { useState } from "react";
import { MenuItems } from "./AboutMenuItems";
import { Link } from "react-scroll";
import "./AboutUsDropdown.css";

function AboutUsDropdown() {
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  return(
    <>

     


      <ul onClick={handleClick}
      className={click ? 'aboutus-dropdown-menu clicked' : 'aboutus-dropdown-menu'}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}
  
export default AboutUsDropdown
  
  