import styled from 'styled-components'
import { Link } from "react-router-dom"

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#EF3D3B' : 'limegreen')};
    height: 50px;
    top:80px;
    position:sticky;
    z-index:8;
`

export const InfoContainer2 = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#EF3D3B' : '#EF3D3B')};
    height: 50px;
    top:80px;
    position:sticky;
    z-index:8;
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 100px;
    min-height: 50px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 40px;
   
    

    @media screen and (max-width: 768px){
        min-height: 100px;
    }

`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns:minmax(auto,1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    };
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: ${({ lightTop }) => (lightTop ? '#232C63' : '#F6B519')};
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? 'white' : '#F6B519')};

    @media screen and (max-width: 480px){
        font-size: 32px;
    };
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#232C63' : 'white')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const eventLink = styled(Link)`
color:white;

&:hover{
    color: #dadbdd;
}

&:activate{
    color:white;
}
`


