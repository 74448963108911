import React from 'react'
import myImage from '../../images/town.svg'
import { Button } from '../ButtonElement'
import { data } from './eventData'
import eventImg  from '../../images/delivery_truck.svg'
import Table from './tableBuilder'
import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2,
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    ImgWrap, 
    Img,
    TextWrapper2,
    TopLine2,
    Heading2,
    Subtitle2,
    eventTable
} from './EventMainElements'
const EventMainSection = ({lightBg,id,imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2}) => {
  return (
    <>
        <InfoContainer  id='events'>
            <InfoWrapper>
                <InfoRow>
                    <Column1>
                        <TextWrapper>
                            <TopLine>Knights in Action</TopLine>
                            <Heading>Upcoming Events</Heading>
                            <Table data={data}/>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                    <ImgWrap>               
                        <Img  src={eventImg}/>  
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default EventMainSection
