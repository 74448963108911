import React, { useState } from 'react'
import  Button  from './Button'
import { Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll';
import './Navbar.css'
import AboutUsDropdown from './AboutUsDropdown'
import EventDropdown from './EventsDropdown'
import {FaBars, FaTimes} from 'react-icons/fa'
import { aboutDropdown } from './NavItems'
import { eventDropdown } from './NavItems'
import kcLogo from '../../images/kofc_r_hz_rgb_rev.png'



function CKNavbar() {
  const [click, setClick] = useState(false)
  const [aboutDropDown, setAboutDropDown] = useState(false)
  const [eventsDropDown, setEventsDropDown] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const onMouseEnterAbout = () => {
    if(window.innerWidth < 960){
      setAboutDropDown(false)
    } else {
      setAboutDropDown(true)
      setClick(true)
    }
  }

  const onMouseLeaveAbout = () => {
    if(window.innerWidth < 960){
      setAboutDropDown(false)
    } else {
      setAboutDropDown(false)
    }
  }

  const onMouseEnterEvents = () => {
    if(window.innerWidth < 960){
      setEventsDropDown(false)
    } else {
      setEventsDropDown(true)
    }
  }

  const onMouseLeaveEvents = () => {
    if(window.innerWidth < 960){
      setEventsDropDown(false)
    } else {
      setEventsDropDown(false)
    }
  }

  return (
    <>
     
      <nav className='navbar'>
      
       <div className = 'navbarContainer'>
        <img src={kcLogo} />
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
        </div>
        
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <LinkS to='home' className= 'nav-links' onClick={closeMobileMenu}>
              Home
            </LinkS>
          </li>
          
          <li className='nav-item'
            onMouseEnter={onMouseEnterAbout}
            onMouseLeave={onMouseLeaveAbout}
            >
            <LinkS to='about' className= 'nav-links'  onClick={closeMobileMenu}>
              About Us
            </LinkS>
            {aboutDropDown && <AboutUsDropdown/>}
          </li>
          
          <li className='nav-item'
            onMouseEnter={onMouseEnterEvents}
            onMouseLeave={onMouseLeaveEvents}
          >
            <LinkS to='events' className='nav-links'  onClick={closeMobileMenu}>
              Events
            </LinkS>
            {eventsDropDown && <EventDropdown/>}
          </li>
          
          <li className='nav-item'>
            <LinkS to='becomeAKnight' className= 'nav-links'  onClick={closeMobileMenu}>
              Membership Benefits
            </LinkS>
          </li>

       
     
          
        </ul>
       
       </div> 
      
      </nav>
    </>

  )
  

}



export default CKNavbar;

