import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import CKNavBar  from '../components/CKNavBar'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import OfficersSection from '../components/Officers'
import InfoSectionNoButton from '../components/InfoSectionNoButton'
import InfoSectionWithDownload from '../components/InfoSectionWithDownload'
import InfoSectionModal from '../components/InfoSectionModal'
import EventMainSection from '../components/EventMainSection'
import RipSection from '../components/RIPSection'
import SpecialEventSection from '../components/SpecialEventSection'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, homeObjFive, homeObjSix, homeObjSeven, homeObjTen, homeObjMCHSScholarship } from '../components/InfoSection/Data'
import { homeObjEight, homeObjNine, homeObjEleven, homeObjTwentyOne, coats4kids2022  } from '../components/InfoSectionNoButton/Data'
import { homeObjTwentyOneX } from '../components/InfoSectionModal/Data'
import { homeObjModalOne } from '../components/InfoSectionModal/Data'
import Footer from '../components/Footer'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
       <Sidebar isOpen={isOpen} toggle={toggle}/>     
       {<CKNavBar />  }      
       <SpecialEventSection />  
      
       {/*<Navbar toggle={toggle}/> */}
       <HeroSection/>
       {<InfoSection {...homeObjOne} />}
       
       <EventMainSection />
       <InfoSectionWithDownload { ...homeObjMCHSScholarship} />
       <InfoSectionNoButton {...coats4kids2022} />
       <InfoSection {...homeObjFive}/>
       <InfoSection {...homeObjFour}/>
       <InfoSection {...homeObjSix}/>
       <InfoSection {...homeObjSeven}/>
       <InfoSectionNoButton {...homeObjEight}/>
       <InfoSectionNoButton {...homeObjNine}/>
       <OfficersSection />
       <InfoSection {...homeObjThree}/>
       <InfoSectionNoButton {...homeObjEleven}/>
       <InfoSectionModal {...homeObjModalOne}/> 
       
       
       <RipSection />
       <Footer />       
    </>
  )
}

export default Home