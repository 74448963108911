import React from 'react'
import myImage from '../../images/town.svg'
import { Button } from '../ButtonElement'
import './eventLink.css'
import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2,
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    ImgWrap, 
    Img,
    eventLink
} from './specialEventElements'
const SpecialEventSection = ({lightBg,id,imgStart, topLine, lightText, headline, darkText, description, buttonLabel, buttonPath, img, alt, primary, dark, dark2, lightTop}) => {
  return (
    <>
        <InfoContainer lightBg={'lightBg'} id={id}>
          <InfoWrapper>
          
            <eventLink>
                <a href="https://4agc.com/fundraiser_pages/bfc00965-5ab0-4a6a-830f-50f33a29a944" target="_blank" rel="noopener noreferrer">Click here to donate to the ID Drive campaign</a>
    
            </eventLink>
         
          
          </InfoWrapper>
          
        </InfoContainer>
        <InfoContainer lightBg={darkText} id={id}>
          <InfoWrapper>
          
            <eventLink>
                <a href="https://4agc.com/commerce_pages/e7c265f3-1b92-4a8e-8c51-9af231febffd" target="_blank" rel="noopener noreferrer">Click here to purchase vocation raffle tickets. ATTENTION: At checkout enter "460" in the "Please Credit Council" field.</a>
    
            </eventLink>
         
          
          </InfoWrapper>
          
        </InfoContainer>

    </>
  )
}

export default SpecialEventSection

