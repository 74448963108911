import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background-color:black;
    @media screen and (max-width: 768px){
        padding: 100px 0;
    }
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 100vh;
    min-height:800px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    
    padding: 0 24px;
    justify-content: center;
`

export const InfoRow = styled.div`
    display: flex;
    align-items: center;
    
   
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

  
`



export const TextWrapper = styled.div`
    max-width: 540px;
    padding-bottom: 60px;
    padding-top: 80px;

`
export const TextWrapper2 = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`


export const TopLine = styled.p`
    color: #f6b519;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const TopLine2 = styled.p`
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color '#232C63';

    @media screen and (max-width: 480px){
        font-size: 32px;
    };
`

export const Heading2 = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #232C63';

    @media screen and (max-width: 480px){
        font-size: 32px;
    };
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: '#232C63';
`

export const Subtitle2 = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#232C63' : 'white')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width:555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`

export const eventTable = styled.table`
    caption-side: top;
    border: none;
    border-collapse: collapse;

    caption-side: bottom;
    td,
    th {
        border: none;
    }

    td{
        padding: 5px 10px
    }

    tbody tr{
        :nth-of-type(odd){
            background-color:#232C63;
        }
        :hover{
            background-color: yellow;
        }
    }

    thead > tr {
        background-color: red;
    }

    caption{
        font-size: 0.9em;
        padding: 5px;
        font-weight: bold;
    }
`