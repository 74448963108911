export const MenuItems = [
    {
      id: 1,
      title: "Council 460",
      path: "about",
      cName: "aboutus-dropdown-link",
    },
    {
      id: 2,
      title: "Meetings",
      path: "meetings",
      cName: "aboutus-dropdown-link",
    },
    {
      id: 3,
      title: "Officers",
      path: "officers",
      cName: "aboutus-dropdown-link",
    },
    {
      id: 4,
      title: "RIP",
      path: "rip",
      cName: "aboutus-dropdown-link",
    }

  ];