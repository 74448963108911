import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'
import "./myLink.css";

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
    background:linear-gradient(to right,rgba(65, 0, 255, 0.4),rgba(255, 0, 232, 0.3));

    

`

export  const HeroBG = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height:100%;
    overflow: hidden;
`

export const VideoBG = styled.video`
    width: 100vw;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    background: green;
    opacity: 80%;
    z-index: -1;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
filter: grayscale(100%);
`

export const HeroContent = styled.div`
    z-index: 1;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

`

export const HeroH1 = styled.h1`
    color: #F6B519;
    font-size: 56px;
    font-weight: bold;
    text-align: center;
    -webkit-text-stroke: 1px black;
    @media screen and ( max-width: 768px){

        font-size: 40px;
    }

    @media screen and ( max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #232C63;
    -webkit-text-stroke: 1px #F6B519;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    max-width: 675px;

    @media screen and ( max-width: 768px){

        font-size: 24px;
    }

    @media screen and ( max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items:center;



`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

