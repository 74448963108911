export const data = [



{
    "Date":"9-12",
    "Event":"Mass for Military  6pm @ SSPP",
},
{
    "Date":"9-12",
    "Event":"Council Meeting 7:30pm @ SSPP",
},
{
    "Date":"9-13",
    "Event":"ID DRIVE (TOOTSIE ROLL DRIVE)",
},
{
    "Date":"9-14",
    "Event":"ID DRIVE (TOOTSIE ROLL DRIVE)",
},
{
    "Date":"9-15",
    "Event":"ID DRIVE (TOOTSIE ROLL DRIVE)",
},
{
    "Date":"10-2",
    "Event":"Blood Drive - Parish Center 1pm - 5pm",
},
{
    "Date":"10-9",
    "Event":"40 Days for Life Day",
},
{
    "Date":"10-10",
    "Event":"Mass for Military - 6pm @ SSPP",
},
{
    "Date":"10-10",
    "Event":"Council Meeting 7:30pm @ SSPP",
},
{
    "Date":"10-11",
    "Event":"Oktoberfest - Root Beer Float Stand",
},
{
    "Date":"10-12",
    "Event":"Oktoberfest - Root Beer Float Stand",
},
{
    "Date":"10-13",
    "Event":"Oktoberfest - Root Beer Float Stand",
},
{
    "Date":"10-18",
    "Event":"ID Drive (Tootsie Roll Drive) at Godfrey Schnucks",
},
{
    "Date":"10-19",
    "Event":"ID Drive (Tootsie Roll Drive) at Godfrey Schnucks",
},
{
    "Date":"10-20",
    "Event":"ID Drive (Tootsie Roll Drive) at Godfrey Schnucks",
},
{
    "Date":"11-9",
    "Event":"Food for Families Collection with Boy Scouts - dropoff",
},
{
    "Date":"11-14",
    "Event":"Mass for Military  6pm @ SSPP",
},

{
    "Date":"11-14",
    "Event":"Council Meeting 7:30pm @ SSPP",
},
{
    "Date":"11-16",
    "Event":"Food for Families Collection with Boy Scouts - pickup",
},
];