import React from 'react'
import { Button } from '../ButtonElement'
import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2,
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    ImgWrap, 
    Img
} from './InfoSectionNoButtonElements'
const InfoSectionNoButton = ({lightBg,id,imgStart, topLine, lightText, headline, darkText, description, buttonLabel, buttonPath, img, alt, primary, dark, dark2, lightTop}) => {
  return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine lightTop={lightTop}>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                        
                        <Img  src={require(`../../images/${img}`)} alt={alt}/>
                            
                        </ImgWrap>
                     </Column2>
                </InfoRow>
            </InfoWrapper>

        </InfoContainer>
    </>
  )
}

export default InfoSectionNoButton

