import styled from 'styled-components'

const StyledTable = styled.table`
width:100%;
caption-side: top;
border: none;
border-collapse: collapse;
/* border-collapse: separate; */
/* border-spacing: 5px 10px; */

caption-side: bottom;
/* empty-cell: show | hide;  */
/* empty-cell is a property of table or the cells themselves */

/* vertical-align: baseline | sub | super | text-top | 
              text-bottom | middle | top | bottom | 
              <percentage> | <length> */

/* tbody {
  vertical-align: top;
}              */
td,
th {
  border: none;
}
/* td,
th {
  border: 1px solid;
} */

td {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.7em;
  color:#f6b519;
  
}

tbody tr {
background-color:black;
  :hover {
    background-color: #EF3D3B;
  }
}
thead > tr {
  background-color: #232c63;
  color:#f6b519;
  padding-bottom:3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.8em;
  
}
caption {
  font-size: 0.8em;
  padding: 5px;
  font-weight: bold;
}
`;
export default ({ data }) => (
<TableMarkup titles={Object.keys(data[0])} data={data} />
);
const TableMarkup = ({ titles, data }) => (
<StyledTable>
  <colgroup>
    <col />
    <col />
    <col />
  </colgroup>
  <thead>
    <tr>
      <th align='left'>First Name</th>
      <th align='left'>Last Name</th>
      <th align='left'>City</th>
      <th align='left'>State</th>
      <th>Years in KofC</th>
      <th>Died</th>
    </tr>
  </thead>
  <tbody>
    {data.map((item, index) => (
      <tr key={index}>
        {titles.map((title, index) => (
          <td key={index}>{item[title]}</td>
        ))}
      </tr>
    ))}
  </tbody>
  {/* <tfoot>
    <tr>
      {titles.map((title, index) => (
        <th key={index}>{title}</th>
      ))}
    </tr>
  </tfoot> */}
</StyledTable>
);
