import React from 'react'
import {useState} from 'react'
import { MdTextSnippet } from 'react-icons/md'
import myImage from '../../images/town.svg'
import Modal from '../modal/Modal'
import { Button } from '../ButtonElement'


import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2,
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    ImgWrap, 
    Img,
    mButton
} from './InfoSectionModalElements'
const InfoSectionModal = ({lightBg,id,imgStart, topLine, lightText, headline, darkText, description, buttonLabel, buttonPath, img, alt, primary, dark, dark2, lightTop,modalHeaderVal,modalContentVal}) => {
const [modalActive, setModalActive] = useState(false)

    return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine lightTop={lightTop}>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>  
                            <BtnWrap>
                                <Button onClick={() => setModalActive(true)}>{buttonLabel}</Button>  
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                        
                        <Img  src={require(`../../images/${img}`)} alt={alt}/>
                            
                        </ImgWrap>
                     </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
        <Modal
            active={modalActive}
            hideModal={() => setModalActive(false)}
            title={modalHeaderVal}
            footer={<Button onClick={() => setModalActive(false)}>close</Button>}
        >
            {modalContentVal}
        </Modal>

    </>
  )
}

export default InfoSectionModal



