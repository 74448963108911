import React from 'react'
import kcLogo from '../../images/kofc_r_emblem_rgb_rev.png'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLogoContainer, footerImage,FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, FooterLinkText, FooterLinkText2 } from './FooterElements'

const Footer = () => {
    return(
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> Knights Of Columbus </FooterLinkTitle>
                                <a href="https://www.kofc.org/en//index.html"><FooterLinkText>Supreme Council</FooterLinkText></a>
                                <a href="https://www.illinoisknights.org/home"><FooterLinkText>State Council</FooterLinkText></a>
                                <a href="https://www.kofc.org/en/what-we-do/insurance/find-an-agent.html"><FooterLinkText>Find An Agent</FooterLinkText></a>
                                <a href="https://www.kofc.org/oo"><FooterLinkText>Reports Online</FooterLinkText></a> 
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Alton Council 460 </FooterLinkTitle>
                                <a href="#about"><FooterLinkText>About Us</FooterLinkText></a>
                                <a href="#events"><FooterLinkText>Upcoming Events</FooterLinkText></a>
                                <a href="#officers"><FooterLinkText>Officers</FooterLinkText></a>
                                <a href="#becomeAKnight"><FooterLinkText>Join Us</FooterLinkText></a>
                                <img src={kcLogo} width = "60" height = "60"/> 
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                           <FooterLinkTitle> Meetings</FooterLinkTitle>
                                <FooterLinkText2>2nd Thursday of month <br/>7:30pm @ Ss. Peter & Paul </FooterLinkText2>
                                <FooterLinkText2>Rosary at 7:05pm before each meeting.</FooterLinkText2>
                           
                        </FooterLinkItems>
                
                            
                  

                    </FooterLinksWrapper>
                </FooterLinksContainer>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer