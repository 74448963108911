import React from 'react'
import myImage from '../../images/town.svg'
import pdfFile from '../../images/KofC_MCHS_Scholarship.pdf'
import { Button } from '../ButtonElement'
import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2,
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    ImgWrap, 
    Img
} from './InfoSectionWithDownloadElements.js'
const InfoSectionWithDownload = ({lightBg,id,imgStart, topLine, lightText, headline, darkText, description, buttonLabel, buttonPath, img, alt, primary, dark, dark2, lightTop}) => {
  return (
    <>

       

        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine lightTop={lightTop}>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>  
                            <BtnWrap>
                            <a href={pdfFile} download="Scholarship Application" target='_blank'>
                               <Button>{buttonLabel}</Button>
                            </a>

                        

                                
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                        
                        <Img  src={require(`../../images/${img}`)} alt={alt}/>
                            
                        </ImgWrap>
                     </Column2>
                </InfoRow>
            </InfoWrapper>

        </InfoContainer>
    </>
  )
}

export default InfoSectionWithDownload

function ExLink() {
    // 👇️ using window.location.href 👇️
    window.location.href = 'https://google.com';
    return null;
  }



