export const data = 
[
  {
    "FirstName": "VINCENT N.",
    "LastName": "MANNS",
    "City": "ALTON",
    "State": "IL",
    "Years": "67",
    "Died": "07-15-2023"
  },
  {
    "FirstName": "FLOYD E.",
    "LastName": "FESSLER",
    "City": "ALTON",
    "State": "IL",
    "Years": "--",
    "Died": "06-12-2023"
  },

  
  {
   "FirstName": "STEPHEN G",
   "LastName": "SALLE",
   "City": "BRIGHTON",
   "State": "IL",
   "Years": "37",
   "Died": "12-28-2022"
 },
 {
   "FirstName": "JACK E",
   "LastName": "DIXON",
   "City": "ALTON",
   "State": "IL",
   "Years": "61",
   "Died": "12-22-2022"
 },
 {
   "FirstName": "ROBERT ",
   "LastName": "DIXON",
   "City": "GODFREY",
   "State": "IL",
   "Years": "64",
   "Died": "11-14-2022"
 },
 {
   "FirstName": "NORVAL M",
   "LastName": "COX",
   "City": "ALTON",
   "State": "IL",
   "Years": "6",
   "Died": "10-05-2022"
 },
 {
   "FirstName": "CHARLES E",
   "LastName": "GOEN",
   "City": "ALTON",
   "State": "IL",
   "Years": "64",
   "Died": "09-13-2022"
 },
 {
   "FirstName": "MARK ",
   "LastName": "RICHARDSON",
   "City": "ALTON",
   "State": "IL",
   "Years": "41",
   "Died": "07-22-2022"
 },
 {
   "FirstName": "SAMUEL C",
   "LastName": "ARNOLD",
   "City": "ALTON",
   "State": "IL",
   "Years": "53",
   "Died": "05-18-2022"
 },
 {
   "FirstName": "KENNETH J",
   "LastName": "TORREZ",
   "City": "BRIGHTON",
   "State": "IL",
   "Years": "13",
   "Died": "03-12-2022"
 },
 {
   "FirstName": "DONALD E",
   "LastName": "HUBER",
   "City": "GODFREY",
   "State": "IL",
   "Years": "42",
   "Died": "03-05-2022"
 },
 {
   "FirstName": "JOHN R",
   "LastName": "MCMANIS",
   "City": "ALTON",
   "State": "IL",
   "Years": "30",
   "Died": "11-24-2021"
 },
 {
   "FirstName": "WILLIAM J",
   "LastName": "CURLOVIC",
   "City": "ALTON",
   "State": "IL",
   "Years": "36",
   "Died": "10-21-2021"
 },
 {
   "FirstName": "JOHN H",
   "LastName": "GODAR",
   "City": "ALTON",
   "State": "IL",
   "Years": "55",
   "Died": "10-17-2021"
 },
 {
   "FirstName": "JAMES JIM L",
   "LastName": "GROSS",
   "City": "GODFREY",
   "State": "IL",
   "Years": "10",
   "Died": "09-02-2021"
 },
 {
   "FirstName": "JAMES JIM B",
   "LastName": "HENDRICKSON",
   "City": "ALTON",
   "State": "IL",
   "Years": "57",
   "Died": "08-17-2021"
 },
 {
   "FirstName": "WILLIAM R",
   "LastName": "HAINE",
   "City": "ALTON",
   "State": "IL",
   "Years": "39",
   "Died": "08-16-2021"
 },
 {
   "FirstName": "WILLIAM J",
   "LastName": "SCHROEDER",
   "City": "ALTON",
   "State": "IL",
   "Years": "34",
   "Died": "07-23-2021"
 },
 {
   "FirstName": "CHARLES A",
   "LastName": "HEINTZ",
   "City": "ALTON",
   "State": "IL",
   "Years": "47",
   "Died": "07-04-2021"
 },
 {
   "FirstName": "THEODORE F",
   "LastName": "KLASNER",
   "City": "GODFREY",
   "State": "IL",
   "Years": "53",
   "Died": "05-25-2021"
 },
 {
   "FirstName": "ROBERT L",
   "LastName": "LEESON",
   "City": "GODFREY",
   "State": "IL",
   "Years": "67",
   "Died": "04-25-2021"
 },
 {
   "FirstName": "JOSEPH H",
   "LastName": "KUHNLINE",
   "City": "ALTON",
   "State": "IL",
   "Years": "69",
   "Died": "03-01-2021"
 },
 {
   "FirstName": "THOMAS J",
   "LastName": "ACKER",
   "City": "ALTON",
   "State": "IL",
   "Years": "40",
   "Died": "02-19-2021"
 },
 {
   "FirstName": "EMMETT ",
   "LastName": "FITZGERALD",
   "City": "GOCFREY",
   "State": "IL",
   "Years": "33",
   "Died": "01-18-2021"
 },
 {
   "FirstName": "THOMAS A",
   "LastName": "PAPIN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "41",
   "Died": "11-17-2020"
 },
 {
   "FirstName": "LEONARD H",
   "LastName": "POHLMAN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "43",
   "Died": "09-16-2020"
 },
 {
   "FirstName": "VICTOR P",
   "LastName": "GOEKEN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "42",
   "Died": "05-29-2020"
 },
 {
   "FirstName": "WILLIAM C",
   "LastName": "ACKERMAN",
   "City": "ALTON",
   "State": "IL",
   "Years": "40",
   "Died": "05-17-2020"
 },
 {
   "FirstName": "KARL MIKE ",
   "LastName": "WIECKHORST",
   "City": "GODFREY",
   "State": "IL",
   "Years": "32",
   "Died": "04-26-2020"
 },
 {
   "FirstName": "JOHN JACK E",
   "LastName": "ECCLES",
   "City": "GODFREY",
   "State": "IL",
   "Years": "51",
   "Died": "01-06-2020"
 },
 {
   "FirstName": "EDWIN F",
   "LastName": "SASEK",
   "City": "ALTON",
   "State": "IL",
   "Years": "63",
   "Died": "07-05-2019"
 },
 {
   "FirstName": "DR JAMES M",
   "LastName": "CARROW",
   "City": "GODFREY",
   "State": "IL",
   "Years": "56",
   "Died": "05-24-2019"
 },
 {
   "FirstName": "MARK A",
   "LastName": "WUELLNER",
   "City": "ALTON",
   "State": "IL",
   "Years": "71",
   "Died": "04-16-2019"
 },
 {
   "FirstName": "FRANCIS B",
   "LastName": "MAZENKO",
   "City": "GODFREY",
   "State": "IL",
   "Years": "66",
   "Died": "03-06-2019"
 },
 {
   "FirstName": "PAUL A",
   "LastName": "SCHMIDT",
   "City": "ALTON",
   "State": "IL",
   "Years": "",
   "Died": "02-11-2019"
 },
 {
   "FirstName": "CARL T",
   "LastName": "MARKOVITCH",
   "City": "ALTON",
   "State": "IL",
   "Years": "51",
   "Died": "01-17-2019"
 },
 {
   "FirstName": "WILLIAM BILL H",
   "LastName": "NAEVE",
   "City": "JACKSON",
   "State": "MS",
   "Years": "53",
   "Died": "01-06-2019"
 },
 {
   "FirstName": "THOMAS V",
   "LastName": "DOOLEY",
   "City": "ALTON",
   "State": "IL",
   "Years": "62",
   "Died": "12-22-2018"
 },
 {
   "FirstName": "TIMOTHY W",
   "LastName": "MOLLOY",
   "City": "GODFREY",
   "State": "IL",
   "Years": "57",
   "Died": "12-05-2018"
 },
 {
   "FirstName": "WALTER W",
   "LastName": "BROWN",
   "City": "ORCHARD PARK",
   "State": "NY",
   "Years": "56",
   "Died": "10-28-2018"
 },
 {
   "FirstName": "TERRANCE J",
   "LastName": "GODAR",
   "City": "ALTON",
   "State": "IL",
   "Years": "19",
   "Died": "10-05-2018"
 },
 {
   "FirstName": "MARK C",
   "LastName": "HARMON",
   "City": "ALTON",
   "State": "IL",
   "Years": "38",
   "Died": "09-25-2018"
 },
 {
   "FirstName": "JAMES B",
   "LastName": "CLARK",
   "City": "GODFREY",
   "State": "IL",
   "Years": "44",
   "Died": "09-23-2018"
 },
 {
   "FirstName": "GEORGE A",
   "LastName": "LEE",
   "City": "SEMINOLE",
   "State": "FL",
   "Years": "28",
   "Died": "06-24-2018"
 },
 {
   "FirstName": "WAYNE E",
   "LastName": "THOMAS",
   "City": "GODFREY",
   "State": "IL",
   "Years": "22",
   "Died": "06-02-2018"
 },
 {
   "FirstName": "ROBERT E",
   "LastName": "HEWITT",
   "City": "ALTON",
   "State": "IL",
   "Years": "8",
   "Died": "05-28-2018"
 },
 {
   "FirstName": "JOHN L",
   "LastName": "ROGERS",
   "City": "GODFREY",
   "State": "IL",
   "Years": "39",
   "Died": "05-01-2018"
 },
 {
   "FirstName": "HENRY P",
   "LastName": "BEISER",
   "City": "ALTON",
   "State": "IL",
   "Years": "63",
   "Died": "04-08-2018"
 },
 {
   "FirstName": "RICHARD T",
   "LastName": "DISHER",
   "City": "ALTON",
   "State": "IL",
   "Years": "29",
   "Died": "03-07-2018"
 },
 {
   "FirstName": "JOSEPH ",
   "LastName": "PYATT",
   "City": "CHESTERFIELD",
   "State": "MO",
   "Years": "54",
   "Died": "02-24-2018"
 },
 {
   "FirstName": "THOMAS H",
   "LastName": "WUELLNER",
   "City": "ALTON",
   "State": "IL",
   "Years": "30",
   "Died": "02-02-2018"
 },
 {
   "FirstName": "T. PATRICK ",
   "LastName": "KANE",
   "City": "GODFREY",
   "State": "IL",
   "Years": "69",
   "Died": "01-24-2018"
 },
 {
   "FirstName": "MICHAEL M",
   "LastName": "ACCARIO",
   "City": "EDWARDSVILLE",
   "State": "IL",
   "Years": "65",
   "Died": "12-16-2017"
 },
 {
   "FirstName": "ROBERT ",
   "LastName": "BRITTS",
   "City": "ALTON",
   "State": "IL",
   "Years": "12",
   "Died": "10/21/2017"
 },
 {
   "FirstName": "ANTHONY ",
   "LastName": "ROMANO",
   "City": "WOOD RIVER",
   "State": "IL",
   "Years": "59",
   "Died": "7/23/2017"
 },
 {
   "FirstName": "JOHN B",
   "LastName": "MULLEN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "29",
   "Died": "5/12/2017"
 },
 {
   "FirstName": "MAURICE J",
   "LastName": "SCHULTZ",
   "City": "GODFREY",
   "State": "IL",
   "Years": "49",
   "Died": "2/1/2017"
 },
 {
   "FirstName": "ROBERT L",
   "LastName": "RINTOUL",
   "City": "GODFREY",
   "State": "IL",
   "Years": "52",
   "Died": "1/31/2017"
 },
 {
   "FirstName": "JOHN JACK ",
   "LastName": "SEIBOLD",
   "City": "GODFREY",
   "State": "IL",
   "Years": "9",
   "Died": "1/24/2017"
 },
 {
   "FirstName": "JOHN JACK ",
   "LastName": "SCHWARTE",
   "City": "ALTON",
   "State": "IL",
   "Years": "58",
   "Died": "1/1/2017"
 },
 {
   "FirstName": "DONALD ",
   "LastName": "KOCHANSKI",
   "City": "GRAFTON",
   "State": "IL",
   "Years": "17",
   "Died": "11/12/2016"
 },
 {
   "FirstName": "LELAND M",
   "LastName": "SACKMAN",
   "City": "JERSEYVILLE",
   "State": "IL",
   "Years": "21",
   "Died": "10/29/2016"
 },
 {
   "FirstName": "RICHARD J",
   "LastName": "MADAR",
   "City": "ALTON",
   "State": "IL",
   "Years": "39",
   "Died": "7/26/2016"
 },
 {
   "FirstName": "JOHN JACK ",
   "LastName": "TERLISNER",
   "City": "GODFREY",
   "State": "IL",
   "Years": "49",
   "Died": "4/30/2016"
 },
 {
   "FirstName": "J EDWARD ",
   "LastName": "BOERNER",
   "City": "BETHALTO",
   "State": "IL",
   "Years": "37",
   "Died": "3/27/2016"
 },
 {
   "FirstName": "MARION ",
   "LastName": "BUCK",
   "City": "GODFREY",
   "State": "IL",
   "Years": "46",
   "Died": "12/25/2015"
 },
 {
   "FirstName": "SYLVESTER L",
   "LastName": "SCOVELL",
   "City": "ALTON",
   "State": "IL",
   "Years": "28",
   "Died": "11/28/2015"
 },
 {
   "FirstName": "HAROLD J",
   "LastName": "THOMECZEK",
   "City": "GODFREY",
   "State": "IL",
   "Years": "47",
   "Died": "11/22/2015"
 },
 {
   "FirstName": "GEORGE W",
   "LastName": "ULRICH",
   "City": "OFALLON",
   "State": "MO",
   "Years": "55",
   "Died": "11/17/2015"
 },
 {
   "FirstName": "BRIAN A",
   "LastName": "BALKE",
   "City": "ALTON",
   "State": "IL",
   "Years": "29",
   "Died": "7/25/2015"
 },
 {
   "FirstName": "HERBERT H",
   "LastName": "VARBLE",
   "City": "GODFREY",
   "State": "IL",
   "Years": "21",
   "Died": "4/10/2015"
 },
 {
   "FirstName": "RALPH J",
   "LastName": "ZERR",
   "City": "GODFREY",
   "State": "IL",
   "Years": "36",
   "Died": "3/24/2015"
 },
 {
   "FirstName": "DONALD L",
   "LastName": "WENDLE",
   "City": "GODFREY",
   "State": "IL",
   "Years": "1",
   "Died": "3/2/2015"
 },
 {
   "FirstName": "NED H",
   "LastName": "WUELLNER",
   "City": "BRIGHTON",
   "State": "IL",
   "Years": "67",
   "Died": "2/23/2015"
 },
 {
   "FirstName": "PAUL CHRIS C",
   "LastName": "JOHNSON",
   "City": "GODFREY",
   "State": "IL",
   "Years": "13",
   "Died": "2/19/2015"
 },
 {
   "FirstName": "ROBERT BOB R",
   "LastName": "SMITH",
   "City": "GODFREY",
   "State": "IL",
   "Years": "2",
   "Died": "1/12/2015"
 },
 {
   "FirstName": "CYRIL NICK ",
   "LastName": "KOCH",
   "City": "ALTON",
   "State": "IL",
   "Years": "46",
   "Died": "12/5/2014"
 },
 {
   "FirstName": "MICHAEL A",
   "LastName": "BARRETT",
   "City": "GODFREY",
   "State": "IL",
   "Years": "35",
   "Died": "11/22/2014"
 },
 {
   "FirstName": "RALPH H",
   "LastName": "BAAHLMANN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "41",
   "Died": "11/17/2014"
 },
 {
   "FirstName": "DR FRANCIS F",
   "LastName": "VONNAHMEN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "67",
   "Died": "11/8/2014"
 },
 {
   "FirstName": "PAUL A",
   "LastName": "KRIBBS",
   "City": "ALTON",
   "State": "IL",
   "Years": "18",
   "Died": "11/4/2014"
 },
 {
   "FirstName": "THOMAS M",
   "LastName": "STROMSKE",
   "City": "THE VILLAGES",
   "State": "FL",
   "Years": "55",
   "Died": "8/27/2014"
 },
 {
   "FirstName": "ROBERT L",
   "LastName": "WARDEIN",
   "City": "ALTON",
   "State": "IL",
   "Years": "35",
   "Died": "8/19/2014"
 },
 {
   "FirstName": "JOSEPH J",
   "LastName": "KELLY",
   "City": "ALTON",
   "State": "IL",
   "Years": "65",
   "Died": "6/16/2014"
 },
 {
   "FirstName": "KENNETH ",
   "LastName": "GRABNER",
   "City": "AlLTON",
   "State": "IL",
   "Years": "37",
   "Died": "6/16/2014"
 },
 {
   "FirstName": "JAMES A",
   "LastName": "MCDERMOTT",
   "City": "BUNKER HILL",
   "State": "IL",
   "Years": "27",
   "Died": "5/16/2014"
 },
 {
   "FirstName": "WILLIAM BILL S",
   "LastName": "ADAMSON",
   "City": "GODFREY",
   "State": "IL",
   "Years": "56",
   "Died": "3/17/2014"
 },
 {
   "FirstName": "CHARLES R",
   "LastName": "HODGE",
   "City": "ALTON",
   "State": "IL",
   "Years": "17",
   "Died": "11/29/2013"
 },
 {
   "FirstName": "RAY ",
   "LastName": "LEONARD",
   "City": "GODFREY",
   "State": "IL",
   "Years": "51",
   "Died": "10/26/2013"
 },
 {
   "FirstName": "HENRY B",
   "LastName": "SAENZ",
   "City": "GODFREY",
   "State": "IL",
   "Years": "31",
   "Died": "9/30/2013"
 },
 {
   "FirstName": "LARRY F",
   "LastName": "GRAY",
   "City": "ALTON",
   "State": "IL",
   "Years": "13",
   "Died": "9/13/2013"
 },
 {
   "FirstName": "CHAMP C",
   "LastName": "WOODSON",
   "City": "ALTON",
   "State": "IL",
   "Years": "28",
   "Died": "9/4/2013"
 },
 {
   "FirstName": "WILLIAM J",
   "LastName": "POHLMAN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "60",
   "Died": "7/3/2013"
 },
 {
   "FirstName": "TIMOTHY J",
   "LastName": "LYON",
   "City": "ALTON",
   "State": "IL",
   "Years": "5",
   "Died": "5/26/2013"
 },
 {
   "FirstName": "ROBERT BUD J",
   "LastName": "SCHWEGEL",
   "City": "ALTON",
   "State": "IL",
   "Years": "54",
   "Died": "5/23/2013"
 },
 {
   "FirstName": "GEORGE F",
   "LastName": "CRAWFORD",
   "City": "ALTON",
   "State": "IL",
   "Years": "31",
   "Died": "5/21/2013"
 },
 {
   "FirstName": "JACK K",
   "LastName": "KRUEGEL",
   "City": "ALTON",
   "State": "IL",
   "Years": "66",
   "Died": "5/20/2013"
 },
 {
   "FirstName": "MICHAEL W",
   "LastName": "RYNDERS",
   "City": "DIAMONDHEAD",
   "State": "MS",
   "Years": "44",
   "Died": "4/16/2013"
 },
 {
   "FirstName": "JOHN 'JACK' W",
   "LastName": "KELLY",
   "City": "ALTON",
   "State": "IL",
   "Years": "32",
   "Died": "2/19/2013"
 },
 {
   "FirstName": "DONALD M",
   "LastName": "COX",
   "City": "ALTON",
   "State": "IL",
   "Years": "39",
   "Died": "1/15/2013"
 },
 {
   "FirstName": "ERWIN D",
   "LastName": "SNYDERS",
   "City": "ALTON",
   "State": "IL",
   "Years": "44",
   "Died": "1/10/2013"
 },
 {
   "FirstName": "RALPH H",
   "LastName": "BAAHLMANN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "56",
   "Died": "12/25/2012"
 },
 {
   "FirstName": "BOB G",
   "LastName": "CHURCHICH",
   "City": "EDWARDSVILLE",
   "State": "IL",
   "Years": "52",
   "Died": "7/24/2012"
 },
 {
   "FirstName": "JOHN D",
   "LastName": "ERTHAL",
   "City": "BRIGHTON",
   "State": "IL",
   "Years": "45",
   "Died": "5/29/2012"
 },
 {
   "FirstName": "PHILIP F",
   "LastName": "HANRAHAN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "44",
   "Died": "4/17/2012"
 },
 {
   "FirstName": "CHARLES E",
   "LastName": "CRUMBACHER",
   "City": "MIDLOTHIAN",
   "State": "VA",
   "Years": "35",
   "Died": "3/25/2012"
 },
 {
   "FirstName": "JOSEPH J",
   "LastName": "MOLLOY",
   "City": "GODFREY",
   "State": "IL",
   "Years": "70",
   "Died": "3/6/2012"
 },
 {
   "FirstName": "GEORGE B",
   "LastName": "WRIGHT",
   "City": "SAINT LOUIS",
   "State": "MO",
   "Years": "52",
   "Died": "1/21/2012"
 },
 {
   "FirstName": "BERNARD E",
   "LastName": "WEULE",
   "City": "ALTON",
   "State": "IL",
   "Years": "54",
   "Died": "11/4/2011"
 },
 {
   "FirstName": "EDMUND M",
   "LastName": "MORRISEY",
   "City": "GODFREY",
   "State": "IL",
   "Years": "58",
   "Died": "9/12/2011"
 },
 {
   "FirstName": "GEORGE F",
   "LastName": "COWAN",
   "City": "ALTON",
   "State": "IL",
   "Years": "55",
   "Died": "6/24/2011"
 },
 {
   "FirstName": "JOSEPH F",
   "LastName": "MASSALONE",
   "City": "EAST ALTON",
   "State": "IL",
   "Years": "32",
   "Died": "4/23/2011"
 },
 {
   "FirstName": "MEL F",
   "LastName": "RUST",
   "City": "ALTON",
   "State": "IL",
   "Years": "30",
   "Died": "4/20/2011"
 },
 {
   "FirstName": "JEROME F",
   "LastName": "BANGHART",
   "City": "ALTON",
   "State": "IL",
   "Years": "60",
   "Died": "12/23/2010"
 },
 {
   "FirstName": "JEFFREY A",
   "LastName": "RYNDERS",
   "City": "ALTON",
   "State": "IL",
   "Years": "31",
   "Died": "12/10/2010"
 },
 {
   "FirstName": "LESTER E",
   "LastName": "COX",
   "City": "ALTON",
   "State": "IL",
   "Years": "61",
   "Died": "12/8/2010"
 },
 {
   "FirstName": "JAMES J",
   "LastName": "GRANDONE",
   "City": "ALTON",
   "State": "IL",
   "Years": "69",
   "Died": "11/21/2010"
 },
 {
   "FirstName": "MICHAEL J",
   "LastName": "MOLLOY",
   "City": "ALTON",
   "State": "IL",
   "Years": "49",
   "Died": "11/11/2010"
 },
 {
   "FirstName": "JOSEPH P",
   "LastName": "MAHER",
   "City": "ALTON",
   "State": "IL",
   "Years": "47",
   "Died": "10/15/2010"
 },
 {
   "FirstName": "WILLIAM L",
   "LastName": "MAZRUM",
   "City": "ALTON",
   "State": "IL",
   "Years": "50",
   "Died": "9/2/2010"
 },
 {
   "FirstName": "CECIL H",
   "LastName": "ADAMS",
   "City": "ALTON",
   "State": "IL",
   "Years": "51",
   "Died": "8/25/2010"
 },
 {
   "FirstName": "ROBERT J",
   "LastName": "JOHNSON",
   "City": "GODFREY",
   "State": "IL",
   "Years": "57",
   "Died": "8/19/2010"
 },
 {
   "FirstName": "BERNARD T",
   "LastName": "KEENEY",
   "City": "BETHALTO",
   "State": "IL",
   "Years": "60",
   "Died": "7/15/2010"
 },
 {
   "FirstName": "CHARLES P",
   "LastName": "GENNARO",
   "City": "OAK LAWN",
   "State": "IL",
   "Years": "64",
   "Died": "7/1/2010"
 },
 {
   "FirstName": "BURDETTE A",
   "LastName": "BALKE",
   "City": "ALTON",
   "State": "IL",
   "Years": "58",
   "Died": "5/4/2010"
 },
 {
   "FirstName": "PAUL A",
   "LastName": "SCHULZ",
   "City": "GODFREY",
   "State": "IL",
   "Years": "50",
   "Died": "4/11/2010"
 },
 {
   "FirstName": "JOHN A",
   "LastName": "LORSBACH",
   "City": "GODFREY",
   "State": "IL",
   "Years": "50",
   "Died": "1/5/2010"
 },
 {
   "FirstName": "JOSEPH F",
   "LastName": "MALONE",
   "City": "ALTON",
   "State": "IL",
   "Years": "8",
   "Died": "12/1/2009"
 },
 {
   "FirstName": "CLETUS E",
   "LastName": "HENKHAUS",
   "City": "GODFREY",
   "State": "IL",
   "Years": "34",
   "Died": "10/7/2009"
 },
 {
   "FirstName": "BERNARD W",
   "LastName": "SPRINGMAN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "66",
   "Died": "8/5/2009"
 },
 {
   "FirstName": "MAURICE R",
   "LastName": "ROBERTS",
   "City": "ALTON",
   "State": "IL",
   "Years": "34",
   "Died": "7/29/2009"
 },
 {
   "FirstName": "WILLIAM V",
   "LastName": "CRAIN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "66",
   "Died": "5/2/2009"
 },
 {
   "FirstName": "ALBERT J",
   "LastName": "BECHTOLD",
   "City": "SAINT LOUIS",
   "State": "MO",
   "Years": "32",
   "Died": "4/24/2009"
 },
 {
   "FirstName": "THOMAS E",
   "LastName": "STEPHAN",
   "City": "ALTON",
   "State": "IL",
   "Years": "50",
   "Died": "4/15/2009"
 },
 {
   "FirstName": "FRANK J",
   "LastName": "HUBER",
   "City": "GODFREY",
   "State": "IL",
   "Years": "66",
   "Died": "3/16/2009"
 },
 {
   "FirstName": "GENE B",
   "LastName": "WICKENHAUSER",
   "City": "GODFREY",
   "State": "IL",
   "Years": "61",
   "Died": "2/1/2009"
 },
 {
   "FirstName": "HAROLD J",
   "LastName": "JILEK",
   "City": "ALTON",
   "State": "IL",
   "Years": "40",
   "Died": "1/30/2009"
 },
 {
   "FirstName": "RICHARD L",
   "LastName": "STREBEL",
   "City": "PLAINVIEW",
   "State": "IL",
   "Years": "36",
   "Died": "1/26/2009"
 },
 {
   "FirstName": "NORBERT W",
   "LastName": "MOSES",
   "City": "TUCSON",
   "State": "AZ",
   "Years": "47",
   "Died": "1/12/2009"
 },
 {
   "FirstName": "CHARLES E",
   "LastName": "CRUMBACHER",
   "City": "GLEN CARBON",
   "State": "IL",
   "Years": "29",
   "Died": "1/7/2009"
 },
 {
   "FirstName": "THOMAS C",
   "LastName": "JUN",
   "City": "GODFREY",
   "State": "IL",
   "Years": "54",
   "Died": "10/20/2008"
 }
]