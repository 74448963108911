export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: 'About Council 460',
    description: 'Council 460 works closely with the two Alton parishes, St. Mary’s and Ss. Peter & Paul, providing funds for various projects, our members being active participants in their parishes.  Council 460 sponsors an annual golf tournament, Family Fun Night, tootsie-roll fund drive, blood-drives and other charitable endeavors throughout the year.  We welcome new members and look forward to meeting and greeting you.  We look forward to utilizing your talent in advancing our charitable and fraternal work.',
    buttonLabel: 'Become a Knight',
    buttonPath: 'https://www.kofc.org/secure/en/join/join-the-knights.html',
    imgStart: false,
    img: 'town.svg',
    alt: 'image goes here..',
    dark: true,
    primary: true,
    darkText: true,
    lightTop: true
};

export const homeObjFive = {
    id: 'events_GunRaffle',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Events - Gun Raffle',
    headline: 'Pere Marquette Assembly Gun Raffle Information',
    description: 'The Fourth Degree Pere Marquette Assembly holds an annual gun raffle in support of their charitable works. A winner is drawn every month at the Assembly\'s monthly meeting. Click the button below for a list of current drawing winners.',
    buttonLabel: 'View Winners',
    buttonPath: 'https://sites.google.com/site/bethaltoknight/events/gun-raffle-winners',
    imgStart: false,
    img: 'certificate.svg',
    alt: 'image goes here..',
    dark: true,
    primary: true,
    darkText: true,
    lightTop: true
};

export const homeObjThree = {
    id: 'becomeAKnight',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Become a Knight',
    headline: 'Why join the Knights?',
    description: 'Now is an ideal time for Catholic men to consider joining the Alton Knights of Columbus and to become a part of the many charitable works performed by the Alton Knights. The Knights of Columbus is the world \'s largest Catholic fraternal service organization. Founded 130 years ago by the Venerable Father Michael McGivney at St. Mary\'s parish in New Haven, CT, the Knights uphold four key principles as pillars of the order: Charity, Unity, Fraternity and Patriotism.  As a member of the Knights of Columbus you and your family enjoy many benefits, including 12 free issues annually of the Columbia magazine, the world\'s largest Catholic family magazine, eligibility to join the Knights of Columbus top-ranked life insurance program, and many more family and personal benefits.',
    buttonLabel: 'Join Now',
    buttonPath: 'https://www.kofc.org/secure/en/join/join-the-knights.html',
    imgStart: false,
    img: 'certificate.svg',
    alt: 'image goes here..',
    dark: true,
    primary: true,
    darkText: true,
    lightTop: true
};

export const homeObjFour = {
    id: 'events_IDDrive',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Events - ID Drive',
    headline: 'Annual ID (Tootsie Roll) Drive',
    description:  'The Council\'s annual Tootsie Roll Drive (ID Drive) for 2024 will be held the weekend of September 13, 14, and 15. This is our major fundraiser of the year and all funds collected go towards helping those with intellectual disabilities. Benefactors every year include the BeDell Center (ARC), Challenge Unlimited, Beverly Farms, and Special Olympics. This is a major effort and to be successful requires many volunteers, some on the street corners, some at store fronts, and some in the office. Please contact Mark Droste at 618-660-9890 or Richard Ruedin at 618-304-5135 if you can help. You may also sign up to volunteer online.',
    buttonLabel: '2024 ID Drive Sign Up Sheet - Volunteer Here!',
    buttonPath: 'https://docs.google.com/spreadsheets/d/1b00VqiwWfE1x57xkNcF0hIGEkyxLvVjhpQJhvR-cktQ/edit?usp=sharing',
    imgStart: true,
    img: 'right_direction.svg',
    alt: 'image goes here..',
    dark: true,
    primary: true,
    darkText: false,
    lightTop: false
};

export const homeObjSix = {
    id: 'events_bloodDrive',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Events - Blood Drives',
    headline: 'Council Blood Drive',
    description:  'The next Alton Council 460 Blood Drive will be held on October 2nd at the Parish Center from 1 to 5pm. Please click the button below to schedule a time for your donation.',
    
    buttonLabel: 'Schedule Time Here!',
    buttonPath: 'https://www.redcrossblood.org',
    imgStart: false,
    img: 'bloodDrive.png',
    alt: 'image goes here..',
    dark: false,
    primary: false,
    darkText: true,
    lightTop: true
};

export const homeObjSeven = {
    id: 'events_fortyDays',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Events - Protect Life',
    headline: 'Forty Days For Life',
    description: 'Fellow Knights: Our Council of Knights has been called to support and get involved with the \"40 Days for Life\" call to prayer and call to action. Our Council will participate in a peaceful vigil at the Hope Clinic for Women, 1602 21st Street in Granite City on Wednesday, October 9th, 2024 from 7AM to 7PM as part of the \"40 Days for Life\" focused pro-life effort. This vigil will be segmented into one-hour shifts for a Knight and at least on other person. Jim Schwegel is the Alton Council coordinator for this effort. Please contact Jim at 618-406-0998 or email Jim at jschwegel@charter.net to volunteer for a one hour timeslot in support of this effort and in support of life for the unborn.', 
    
    buttonLabel: '2023 Sign Up Sheet - Volunteer Here!',
    buttonPath: 'https://docs.google.com/spreadsheets/d/1paVluSrubE88ShYQSUrTJTtSi-aXS5XVRar2ELYOkNU/edit#gid=1034885478',
    imgStart: true,
    img: 'baby.svg',
    alt: 'image goes here..',
    dark: true,
    primary: true,
    darkText: false,
    lightTop: false
};

export const homeObjEight = {
    id: 'events_familyFunNight',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Events - Family',
    headline: 'Family Fun Night',
    description: 'The Alton Knights recently sponsored a \"Family Fun Night\" on the parking lot of St. Mary\'s School. There were multiple bounce houses, free kettle corn and snow cones, face painting and food and beverages.',
    
    buttonLabel: 'Get started',
    buttonPath: 'http://www.yahoo.com',
    imgStart: false,
    img: 'famFunNight.png',
    alt: 'image goes here..',
    dark: false,
    primary: false,
    darkText: true,
    lightTop: true
};

export const homeObjNine = {
    id: 'events_golf',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Events - Annual Golf Tournament',
    headline: 'Alton Knights of Columbus Annual Golf Tournament',
    description: 'The Alton Knights of Columbus annual golf tournament was very successful with 56 golfers participating. We would like to thank the golfers that participated and give special thanks to the sponsors for the event.',
    
    buttonLabel: 'Get started',
    buttonPath: 'http://google.com',
    imgStart: true,
    img: 'golf2022.png',
    alt: 'image goes here..',
    dark: true,
    primary: true,
    darkText: false,
    lightTop: false
};

export const homeObjTen = {
    id: 'events_bowling',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Events - Bowling',
    headline: 'Alton Knights of Columbus Bowling League',
    description: 'Starting in 1923 the Alton Knights of Columbus Bowling League is one of the longest running continuous bowling leagues in the state of Illinois. The league bowls on Monday nights at 6:45 at Bowl Haven Lanes in Alton. If you are interested in joining our league please contact Jim Schwegel at 406-0998. For more historical information about the league click the button below.',
    
    buttonLabel: 'View Historical Info',
    buttonPath: '#bowlingHistory',
    imgStart: false,
    img: 'bowlingPic.jpeg',
    alt: 'image goes here..',
    dark: false,
    primary: false,
    darkText: true,
    lightTop: true
};


export const homeObjMCHSScholarship = {
    id: 'events_scholarship',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'MCHS Scholarship',
    headline: 'Alton Knights of Columbus Council 460 Louis Lee Lohman Scholarship',
    description: 'Annually, Council 460 offers a scholarship to a child or grandchild of a Council 460 member to attend Marquette Catholic High School. This scholarship was established in memory of Louis Lee Lohman’s generous endowment to the Council. Lee was a graduate of Marquette Catholic High School, a member of Saints Peter and Paul Catholic Church, and a lifelong member of Council 460. ',
    buttonLabel: 'Download Application Here',
    buttonPath: '#images/KofC_MCHS_Scholarship.pdf',
    imgStart: true,
    img: 'road2knowledge.svg',
    alt: 'image goes here..',
    dark: false,
    primary: false,
    darkText: true,
    lightTop: true
};