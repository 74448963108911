import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SideBtnWrap,
    SidebarRoute} from './SidebarElements'
export const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to='about' onClick={toggle}>
                    AWWWWWWW
                </SidebarLink>
                <SidebarLink to='events' onClick={toggle}>
                    Events
                </SidebarLink>
                <SidebarLink to='becomeAKnight' onClick={toggle}>
                    Membership Benefits
                </SidebarLink>       
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to='https://www.kofc.org/secure/en/join/join-the-knights.html'>Sign In</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar